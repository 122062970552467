<template>
  <div class="h-100">
    <b-form-group
      class="wameed-input text-book-16 text-font-main"
      :class="fieldClasses"
      :label="label"
      :label-for="id"
    >
      <validation-provider
        #default="{ errors }"
        :name="name"
        :vid="name"
        :rules="rules"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid' : null"
        >
        <b-dropdown
            :id="id"
            :disabled="disabled"
            :class="classes"
            :variant="variant"
            :text="title"
            class="dropdown-icon-wrapper"
          >
            <template #button-content>
              <div class="d-flex justify-content-between align-items-center">
                <span class="mr-1" :class="valueClasses">{{ title }}</span>

                <arrow-down-icon />
              </div>
            </template>
            <b-dropdown-item
              variant="white"
              :class="item.value == title ? 'active' : ''"
              v-for="(item, i) in items"
              :key="i"
              @click="dropdownClick(item)"
            >
              <span class="text-medium-16 text-font-secondary">{{
              translate? $t(item[text]):item[text]
              }}</span>
            </b-dropdown-item>  
          </b-dropdown>
        </b-input-group>

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { required, email } from "@validations"; 
export default {
  props: {
    classes: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    selectedItem: {
      type: Array,
      default: null,
    },
    valueClasses: {
      type: String,
      default: null,
    },
    fieldClasses: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    translate: {
      type: Boolean,
      default: null,
    },
  },
  components: {
    ValidationProvider,
  },
  data() {
    return {
      // validation rules
      required,
      selected: [],
      email,
      options: [
        { text: "Orange", value: "orange" },
        { text: "Apple", value: "apple" },
        { text: "Pineapple", value: "pineapple" },
        { text: "Grape", value: "grape" },
      ],
    };
  },
  mounted() {
    // this.$refs.multiDropdown.hide();
  },
  methods: {
    dropdownClick(item) {
      // this.$refs.multiDropdown.bv::dropdown::hide

      this.$root.$emit("bv::dropdown::hide");
      this.$emit("dropdownClick", item);
    },
  },
};
</script>
